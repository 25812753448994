import React from 'react';
import './Companylicenses.css'; // Importing CSS from the same components folder
function Companylicenses() {
  return (
    <div>
      <h2>Our Company Licenses </h2>
      <div className="companylicenses-container">
      <p><strong>CIN:</strong> U01100AP2022PTC120948</p>
        <p><strong>GST:</strong> 37AAWCA5909P1ZO</p>
        <p><strong>PAN:</strong> AAWCA5909P</p>
        <p><strong>TAN:</strong> VPNA06923A</p>
        <p><strong>MSME – Udyam:</strong> UDYAM-AP-190000020</p>
        <p><strong>DPIIT - Startup India:</strong> DIPP103191</p>
        <p><strong>FSSAI:</strong> 10124005000124</p>
        <p><strong>IEC - DGFT:</strong> AAWCA5909P</p>
        <p><strong>RCMC (APEDA):</strong> RCMC/APEDA/08000/2024-25</p>
      </div>
    </div>
  );
}

export default Companylicenses;
