import React from 'react';
import Gallery from './Gallery';

function Products () {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Product Gallery</h1>
      
      {/* Add product images here */}
      {/*<img src="logo.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4" />
      <img src="logo.png" alt="Farm fresh Banganapalli (safeda)" className="mx-auto w-full max-w-sm mb-4" />
      <img src="logo.png" alt="Totapuri Mango" className="mx-auto w-full max-w-sm" />*/}

      {/* You can add more product images as needed */}

      <Gallery/>
    </div>
  );
};

export default Products;
