import React from 'react';
import './Business.css'; // Importing CSS from the same components folder
function Business() {
  return (
    <div>
      <h2>Our Company Licenses</h2>
      <div className="business-container">
        <p>
          <strong>BUYERS:</strong><br />
          1) The Campco Chocolate Factory, Mangalore<br />
          2) Cadbury/ Mondelez India Pvt Ltd<br />
          3) The ITC Ltd<br />
          4) Mother Dairy<br />
          5) Sila Agri Tech Pvt Ltd<br />
          6) VeGrow<br />
          7) Ayekart<br />
          8) Swadeshi Centra LLC – USA<br />
          9) Vikasa Trading Company<br />
          10) John’s Foods<br /><br />

          <strong>Bankers:</strong><br />
          1. HDFC Bank<br />
          <strong>Account Name:</strong> Antarveda Agros Private Limited<br />
          <strong>Account Number:</strong> 50200069995078<br />
          <strong>IFSC:</strong> HDFC0001641<br />
          <strong>Branch:</strong> Opp: Mandal Office, Beside Indian Bank, Eluru<br />
        </p>
      </div>
    </div>
  );
}

export default Business;
