import React from 'react';
import './Agriculturalproducts.css'; // Importing CSS from the same components folder

function Agriculturalproducts() {
  return (
    <div>
      <h2>Agricultural Products</h2>
      <div className="agriculturalproducts-container">
        <h3>Products</h3>
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Commodities</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Cocoa Beans</td>
              <td>Fermented & Dried Cocoa Beans</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Mangoes</td>
              <td>Farm Fresh Banganapalli (Safeda) and Totapuri</td>
            </tr>
          </tbody>
        </table>

        <h3>Company Unit</h3>
        <p>
           Kuchimpudi, Pedavegi Mandal, Eluru District – Cocoa Beans<br />
           Leelanagar, Nuzvid Mandal, Eluru District – Mangoes<br /><br />
          <strong>Company Registered Office</strong><br />
          D.No. 2-52, High School Road, Near Ramalayam, Sanivarapupeta<br />
          Eluru – 534 003, Eluru District, Andhra Pradesh, India
        </p>

        <h3>Directors</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Position</th>
              <th>Contact</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mr Ch N V Srinivasa Rao</td>
              <td>Managing Director</td>
              <td>9676308587</td>
            </tr>
            <tr>
              <td>Mr Ch Prabhavathi</td>
              <td>Director</td>
              <td>7702273331</td>
            </tr>
          </tbody>
        </table>

        <h3>Bankers</h3>
        <p>
          1. HDFC Bank<br />
          <strong>Account Name:</strong> Antarveda Agros Private Limited<br />
          <strong>Account Number:</strong> 50200069995078<br />
          <strong>IFSC:</strong> HDFC0001641<br />
          <strong>Branch:</strong> Opp: Mandal Office, Beside Indian Bank, Eluru
        </p>
      </div>
    </div>
  );
}

export default Agriculturalproducts;
