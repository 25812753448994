import React from 'react';
import './Gallery.css'; // Importing CSS from the same components folder
function Gallery() {
  return (
    <div>
      <h2>Our Product Gallery</h2>
      <div className="gallery-container">
        {/* Use require() or import images like this */}
        <img src="banana_main.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        
        <img src="lemon_1.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="lemon_2.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="lemon_3.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="lemon_4.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="lemon_main.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="mango_1.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="mango_2.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="mango_3.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="mango_main.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="mango.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>


        <img src="cocoa1.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="cocoa2.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
        <img src="cocoa3.png" alt="Fermented & Dried Cocoa beans" className="mx-auto w-full max-w-sm mb-4"/>
       
        
      </div>
    </div>
  );
}

export default Gallery;
